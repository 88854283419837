@font-face {
    font-family: lightcase;
    src: url(../font/lightcase.eot?55356177);
    src: url(../font/lightcase.eot?55356177#iefix) format("embedded-opentype"), url(../font/lightcase.woff?55356177) format("woff"), url(../font/lightcase.ttf?55356177) format("truetype"), url(../font/lightcase.svg?55356177#lightcase) format("svg");
    font-weight: 400;
    font-style: normal
}

[class*=lightcase-icon-]:before {
    font-family: lightcase, sans-serif;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em
}

.lightcase-icon-play:before {
    content: '\e800'
}

.lightcase-icon-pause:before {
    content: '\e801'
}

.lightcase-icon-close:before {
    content: '\e802'
}

.lightcase-icon-prev:before {
    content: '\e803'
}

.lightcase-icon-next:before {
    content: '\e804'
}

.lightcase-icon-spin:before {
    content: '\e805'
}

@-webkit-keyframes lightcase-spin {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-moz-keyframes lightcase-spin {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-o-keyframes lightcase-spin {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-ms-keyframes lightcase-spin {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes lightcase-spin {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

#lightcase-case {
    display: none;
    position: fixed;
    z-index: 2002;
    top: 50%;
    left: 50%;
    font-family: arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    text-shadow: 0 0 10px rgba(0, 0, 0, .5)
}

#lightcase-loading>span,
#lightcase-nav a[class*=lightcase-icon-]>span {
    display: inline-block;
    text-indent: -9999px
}

@media screen and (min-width:641px) {
    #lightcase-case:not([data-lc-type=error]) #lightcase-content {
        position: relative;
        z-index: 1;
        overflow: hidden;
        text-shadow: none;
        background-color: #fff;
        -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, .5);
        -moz-box-shadow: 0 0 30px rgba(0, 0, 0, .5);
        -o-box-shadow: 0 0 30px rgba(0, 0, 0, .5);
        box-shadow: 0 0 30px rgba(0, 0, 0, .5);
        -webkit-backface-visibility: hidden
    }
    #lightcase-case[data-lc-type=image] #lightcase-content,
    #lightcase-case[data-lc-type=video] #lightcase-content {
        background-color: #333
    }
}

#lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none
}

#lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content .lightcase-contentInner {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box
}

@media screen and (max-width:640px) {
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]),
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content {
        position: relative!important;
        top: auto!important;
        left: auto!important;
        width: auto!important;
        height: auto!important;
        margin: 0!important;
        padding: 0!important;
        border: none!important;
        background: 0 0!important
    }
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content .lightcase-contentInner {
        padding: 15px
    }
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content .lightcase-contentInner,
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content .lightcase-contentInner>* {
        width: 100%!important;
        max-width: none!important
    }
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content .lightcase-contentInner>:not(iframe) {
        height: auto!important;
        max-height: none!important
    }
    .lightcase-isMobileDevice #lightcase-case[data-lc-type=iframe] #lightcase-content .lightcase-contentInner {
        overflow: auto;
        -webkit-overflow-scrolling: touch
    }
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
        position: relative!important;
        top: auto!important;
        left: auto!important;
        width: auto!important;
        height: auto!important;
        margin: 0!important;
        padding: 0!important;
        border: none!important;
        background: 0 0!important
    }
}

@media screen and (max-width:640px) and (min-width:641px) {
    [data-lc-type=image] #lightcase-content .lightcase-contentInner,
    [data-lc-type=video] #lightcase-content .lightcase-contentInner {
        line-height: .75
    }
}

.lightcase-isMobileDevice #lightcase-case[data-lc-type=iframe] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

@media screen and (min-width:641px) {
    #lightcase-case:not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
        padding: 30px;
        overflow: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box
    }
}

@media screen and (max-width:640px) {
    #lightcase-content h1,
    #lightcase-content h2,
    #lightcase-content h3,
    #lightcase-content h4,
    #lightcase-content h5,
    #lightcase-content h6,
    #lightcase-content p {
        color: #aaa
    }
    #lightcase-case p.lightcase-error {
        padding: 30px 0
    }
}

@media screen and (min-width:641px) {
    #lightcase-content h1,
    #lightcase-content h2,
    #lightcase-content h3,
    #lightcase-content h4,
    #lightcase-content h5,
    #lightcase-content h6,
    #lightcase-content p {
        color: #333
    }
    #lightcase-case p.lightcase-error {
        padding: 0
    }
}

#lightcase-case p.lightcase-error {
    margin: 0;
    font-size: 17px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aaa
}

.lightcase-isMobileDevice .lightcase-open body {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden
}

@media screen and (max-width:640px) {
    .lightcase-open body {
        padding: 55px 0 70px
    }
    .lightcase-open body>:not([id*=lightcase-]) {
        position: fixed!important;
        top: -9999px!important;
        width: 0!important;
        height: 0!important;
        overflow: hidden!important
    }
}

#lightcase-info {
    position: absolute;
    padding-top: 15px
}

#lightcase-info #lightcase-caption,
#lightcase-info #lightcase-title {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-weight: 400;
    text-overflow: ellipsis
}

#lightcase-info #lightcase-title {
    font-size: 17px;
    color: #aaa
}

#lightcase-info #lightcase-caption {
    clear: both;
    font-size: 13px;
    color: #aaa
}

#lightcase-info #lightcase-sequenceInfo {
    font-size: 11px;
    color: #aaa
}

@media screen and (max-width:640px) {
    #lightcase-info #lightcase-title {
        position: fixed;
        top: 10px;
        left: 0;
        max-width: 87.5%;
        padding: 5px 15px;
        background: #333
    }
    .lightcase-fullScreenMode #lightcase-info {
        padding-left: 15px;
        padding-right: 15px
    }
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-info {
        position: static
    }
}

#lightcase-loading {
    width: 1.123em;
    height: auto;
    line-height: 1;
    text-align: center;
    position: fixed;
    z-index: 2001;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    opacity: 1;
    font-size: 32px;
    text-shadow: 0 0 15px #fff;
    -moz-transform-origin: 50% 53%;
    -webkit-animation: lightcase-spin .5s infinite linear;
    -moz-animation: lightcase-spin .5s infinite linear;
    -o-animation: lightcase-spin .5s infinite linear;
    animation: lightcase-spin .5s infinite linear
}

#lightcase-loading,
#lightcase-loading:focus {
    text-decoration: none;
    color: #fff;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: color, opacity, ease-in-out .25s;
    -moz-transition: color, opacity, ease-in-out .25s;
    -o-transition: color, opacity, ease-in-out .25s;
    transition: color, opacity, ease-in-out .25s
}

#lightcase-nav a[class*=lightcase-icon-] {
    position: fixed;
    z-index: 9999;
    width: 1.123em;
    height: auto;
    font-size: 38px;
    line-height: 1;
    text-align: center;
    text-shadow: none;
    outline: 0;
    cursor: pointer
}

#lightcase-nav a[class*=lightcase-icon-],
#lightcase-nav a[class*=lightcase-icon-]:focus {
    text-decoration: none;
    color: rgba(255, 255, 255, .6);
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: color, opacity, ease-in-out .25s;
    -moz-transition: color, opacity, ease-in-out .25s;
    -o-transition: color, opacity, ease-in-out .25s;
    transition: color, opacity, ease-in-out .25s
}

#lightcase-nav a[class*=lightcase-icon-]:hover {
    color: #fff;
    text-shadow: 0 0 15px #fff
}

.lightcase-isMobileDevice #lightcase-nav a[class*=lightcase-icon-]:hover {
    color: #aaa;
    text-shadow: none
}

#lightcase-nav a[class*=lightcase-icon-].lightcase-icon-prev {
    left: 15px
}

#lightcase-nav a[class*=lightcase-icon-].lightcase-icon-next {
    right: 15px
}

#lightcase-nav a[class*=lightcase-icon-].lightcase-icon-pause,
#lightcase-nav a[class*=lightcase-icon-].lightcase-icon-play {
    left: 50%;
    margin-left: -.5em
}

#lightcase-nav a[class*=lightcase-icon-].lightcase-icon-close {
    top: 15px;
    right: 15px;
    bottom: auto;
    margin: 0;
    opacity: 0;
    outline: 0
}

@media screen and (max-width:640px) {
    #lightcase-nav a[class*=lightcase-icon-].lightcase-icon-close {
        position: absolute
    }
    #lightcase-nav a[class*=lightcase-icon-] {
        bottom: 15px;
        font-size: 24px
    }
}

@media screen and (min-width:641px) {
    #lightcase-nav a[class*=lightcase-icon-].lightcase-icon-pause,
    #lightcase-nav a[class*=lightcase-icon-].lightcase-icon-play {
        opacity: 0
    }
    #lightcase-nav a[class*=lightcase-icon-].lightcase-icon-close {
        position: fixed
    }
    #lightcase-nav a[class*=lightcase-icon-] {
        bottom: 50%;
        margin-bottom: -.5em
    }
    #lightcase-case:hover~#lightcase-nav a[class*=lightcase-icon-],
    #lightcase-nav a[class*=lightcase-icon-]:hover {
        opacity: 1
    }
}

#lightcase-overlay {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    background: #333
}

@media screen and (max-width:640px) {
    #lightcase-overlay {
        opacity: 1!important
    }
}

@charset 'utf-8';
@import "mixin";
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include motion-ui-transitions;
@include motion-ui-animations;
@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,700);
@font-face {
    font-family: 'skolasansbold';
    src: url('../font/skolasans-bold.eot');
    src: url('../font/skolasans-bold.eot?#iefix') format('embedded-opentype'), url('../font/skolasans-bold.woff2') format('woff2'), url('../font/skolasans-bold.woff') format('woff'), url('../font/skolasans-bold.ttf') format('truetype'), url('../font/skolasans-bold.svg#skolasansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'skolasansregular';
    src: url('../font/skolasans-regular.eot');
    src: url('../font/skolasans-regular.eot?#iefix') format('embedded-opentype'), url('../font/skolasans-regular.woff2') format('woff2'), url('../font/skolasans-regular.woff') format('woff'), url('../font/skolasans-regular.ttf') format('truetype'), url('../font/skolasans-regular.svg#skolasansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'skolasanslight';
    src: url('../font/skolasans-light.eot');
    src: url('../font/skolasans-light.eot?#iefix') format('embedded-opentype'), url('../font/skolasans-light.woff2') format('woff2'), url('../font/skolasans-light.woff') format('woff'), url('../font/skolasans-light.ttf') format('truetype'), url('../font/skolasans-light.svg#skolasansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

$noir: #313131;
$snow: #ffffff;
$rose:#ef4a59;
$orange:#e99554;
$gris:#909090;
$gris_clair:#f6f6f6;
body {
    color: $noir;
    font-family: 'skolasanslight';
    font-weight: 100;
    @include font-size(19px);
    background: $snow;
}

main {
    display: block;
}

.bold {
    font-weight: 700;
}

.row_100 {
    max-width: 80%!important;
}

.row_200 {
    max-width: 100%!important;
}

.width_100 {
    width: 100%!important;
}

.aligne_center {
    text-align: center;
}

.margin_section {
    margin-top: 70px!important;
    margin-bottom: 70px!important;
}

.padding_section {
    padding-top: 70px;
    padding-bottom: 70px;
    max-width: 960px;
    display: block;
    margin: 0 auto;
}

.bg_section {
    background: $gris_clair;
}

.description_modal hr{
    margin-top: 40px;
    margin-bottom: 40px;
}

.clear {
    clear: both;
}

.centerparent {
    position: relative;
}

.centerelement {
    // background: white;
    padding: 20px;
    &.both {
        @include center;
    }
    &.horizontal {
        @include center(true,
        false);
    }
    &.vertical {
        @include center(false,
        true);
    }
}

h1,
h2,
h3,
h4 h5,
h6 {}

H1 {
    /*@include font-size();
        font-family: '';
        color: ;*/
}

h2 {
    @include font-size(50px);
    font-family: 'PT Serif';
    text-transform: uppercase;
    color: $gris;
    text-align: center;
    letter-spacing: 10px;
}

h3 {
    @include font-size(30px);
    font-family: 'PT Serif';
    color: $noir;
    letter-spacing: 6px;
}

h4 {
    @include font-size(20px);
    font-family: 'PT Serif';
    color: $noir;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.cta {
    @include font-size(19px);
    font-family: 'skolasanslight';
    text-transform: uppercase;
    color: $orange;
    border: 1px solid $orange;
    background: $snow;
    text-align: center;
    padding: 5px;
    width: 100%;
    display: block;
    @include transition(.3s);
    &:hover,
    &:focus {
        color: $snow;
        background: $orange;
    }
}

.cta_border_anim:hover::after,
.cta_border_anim:hover::before {
    -webkit-transform: scale(1);
}

.cta_border_anim::before {
    border-bottom: 1px solid $orange;
    border-left: 1px solid $orange;
    -webkit-transform-origin: 0 100%;
    @include transition(1s);
}

.cta_border_anim::after {
    border-top: 1px solid $orange;
    border-right: 1px solid $orange;
    -webkit-transform-origin: 100% 0%;
    @include transition(1.2s);
}

.cta_border_anim::before,
.cta_border_anim::after {
    width: 100%;
    height: 100%;
    z-index: 3;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    @include transform (scale(0));
}

li {
    list-style: none!important;
}

.anim_border_menu {
    display: inline-block;
    position: relative;
    padding-bottom: 1px;
}

.anim_border_menu:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    transition: width 0s ease, background .5s ease;
}

.anim_border_menu:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background: $orange;
    transition: width .5s ease;
}

.anim_border_menu:hover:before,
.active:before {
    width: 100%;
    background: $orange;
    transition: width .5s ease;
}

.anim_border_menu:hover:after,
.active:after {
    width: 100%;
    background: transparent;
    transition: all 0s ease;
}

.sticky_menu {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    border-top: 0;
    background: $snow;
    padding-top: 15px;
}


/*============= HEADER =========================================== */


/*BURGER */

$nav-size: 50px;
$nav-color: #fff;
$nav-color-active: #445566;
$hasBackground: true;
// Advanced Config
$animation-time: 300ms;
$animation-easing: ease-in-out;
$z-index-navigation-button: 500;
$z-index-navigation-inner: $z-index-navigation-button + 25;
@if $hasBackground==false {
    $nav-color: $nav-color-active;
}

* {
    box-sizing: border-box;
}

.material--burger {
    cursor: pointer;
    padding: ($nav-size / 4);
    position: relative;
    width: $nav-size;
    height: $nav-size;
    @if $hasBackground==true {
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $orange;
            animation: material-menu-background $animation-time $animation-easing forwards;
        }
    }
    span {
        top: 50%;
        transform-origin: center center;
        animation-name: material-menu;
        &,
        &:before,
        &:after {
            width: ($nav-size / 2);
            background-color: $nav-color;
            height: ceil($nav-size / 20);
            position: absolute;
            z-index: $z-index-navigation-inner;
            animation-duration: $animation-time;
            animation-fill-mode: forwards;
            animation-timing-function: $animation-easing;
            transition: background-color $animation-time $animation-easing;
        }
        &:before,
        &:after {
            content: '';
            display: block;
        }
        &:before {
            top: 300%;
            transform-origin: right bottom;
            animation-name: material-menu-before;
        }
        &:after {
            bottom: 300%;
            transform-origin: right top;
            animation-name: material-menu-after;
        }
    }
    &.material--arrow {
        @if $hasBackground==true {
            &:after {
                animation: material-arrow-background $animation-time $animation-easing forwards;
            }
        }
        span,
        span:before,
        span:after {
            animation-duration: $animation-time;
            animation-fill-mode: forwards;
            animation-timing-function: $animation-easing;
            background-color: $orange;
        }
        span {
            animation-name: material-arrow;
        }
        span:before {
            animation-name: material-arrow-before;
        }
        span:after {
            animation-name: material-arrow-after;
        }
    }
}

@if $hasBackground==true {
    @keyframes material-arrow-background {
        100% {
            transform: rotate(180deg) scale(0);
        }
    }
    @keyframes material-menu-background {
        0% {
            transform: rotate(180deg) scale(0);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }
}

@keyframes material-arrow {
    100% {
        transform: rotate(270deg);
    }
}

@keyframes material-arrow-before {
    100% {
        top: 0;
        transform: rotate(-40deg) scaleX(0.7);
    }
}

@keyframes material-arrow-after {
    100% {
        bottom: 0;
        transform: rotate(40deg) scaleX(0.7);
    }
}

@keyframes material-menu {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes material-menu-before {
    0% {
        top: 0;
        transform: rotate(-40deg) scaleX(0.7);
    }
    100% {
        top: 300%;
        transform: rotate(0) scaleX(1);
    }
}

@keyframes material-menu-after {
    0% {
        bottom: 0;
        transform: rotate(40deg) scaleX(0.7);
    }
    100% {
        bottom: 300%;
        transform: rotate(0) scaleX(1);
    }
}

.material--burger {
    /* margin: 90px auto;*/
}

.navigation--button {
    width: 20%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 999999;
    display: none;
}


/*BURGER FIN*/

header {
    height: 100%;
    min-height: 100vh;
    padding: 50px;
    nav {
        ul {
            width: 81%;
            max-width: 1037px!important;
            li {
                a {
                    display: block!important;
                    width: 100%!important;
                    text-align: center;
                    @include font-size(20px);
                    font-family: 'PT Serif';
                    color: $noir;
                    text-transform: uppercase;
                    &:hover,
                    &:active,
                    &:focus {
                        color: $orange;
                    }
                }
            }
        }
    }
    .accueil {
        color: $snow!important;
        div {
            width: 80%;
            max-width: 960px;
            opacity: 1;
            h1 {
                a {
                    img {
                        display: block;
                        margin: 0 auto;
                        padding: 30px 30px 0 30px;
                    }
                }
            }
            h2 {
                @include font-size(25px);
                font-family: 'skolasanslight';
                text-transform: initial;
                padding: 30px;
                letter-spacing: 3px;
                color: $snow!important;
            }
            div {
                @include font-size(18px);
                font-family: 'skolasanslight';
                padding: 0px 30px 30px 30px;
                width: 100%!important;
                max-width: 100%;
            }
        }
    }
    /*.margebottom {
        height: 50px;
        width: 94%;
        background: #ffffff;
        position: absolute;
        bottom: 0px;
    }*/
}

.introduction_sous{
    h3{
        padding-top: 30px;
        text-align: center
    }
    .photo_intro{
            width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
    }
}

/*============= SOURIS MOUSE =================== */

.mouse {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 46px;
    border-radius: 13px;
    border: 2px solid $snow;
    bottom: 55px;
    position: absolute;
    left: 50%;
    margin-left: -14px;
    opacity: 1;
    @include transition(.5s);
    span {
        display: block;
        margin: 6px auto;
        width: 2px;
        height: 2px;
        border-radius: 4px;
        background: $snow;
        border: 1px solid transparent;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: scroll;
        animation-name: scroll;
    }
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

.opacity_mouse {
    opacity: 0;
}


/*============= SOURIS MOUSE FIN =================== */


/*============= HEADER FIN =========================================== */


/*============= CONTENT =========================================== */

.zone_photo1{
    
    height: 100%;
    min-height: 500px;
    max-height: 600px;
    overflow: hidden;
    padding-top: 80px;
    margin-bottom: 100px;
    img{
        min-width: 100%;

    }
}


main {
    padding: 0 50px;
}

.lisere {
    &::before {
        content: "";
        display: block;
        height: 1px;
        background: $gris;
        width: 100%;
        margin-bottom: 30px;
    }
    &::after {
        content: "";
        display: block;
        height: 1px;
        background: $gris;
        width: 100%;
        margin-top: 30px;
    }
}


/*============= THERAPEUTE =========================================== */

.therapeute {
    h2 {}
    ul {
        .profils_th{
           &:last-child{
                float: left!important;
            } 
        }
        li {
            margin: 50px auto 0px auto;
            
            a {
                display: block;
                height: 100%;
                @include transition(.3s);
                position: relative;
                &:hover {
                    .cta {
                        color: $snow;
                        background: rgba($orange, .75);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        padding-top: 30px;
                    }
                    img {
                        @include filter(grayscale,
                        0%);
                    }
                }
                img {
                    @include filter(grayscale,
                    100%);
                    @include transition(.5s);
                    width: 100%;
                }
                span {
                    @include transition(1s);
                    position: absolute;
                    top: 90%;
                    bottom: -5%;
                    overflow: hidden;
                        padding-top: 16px;
                }
            }
        }
    }
}


/*============= THERAPEUTE FIN =========================================== */


/*============= EVENEMENTS =========================================== */

.evenement {
    h2 {}
    ul {
        li {
            margin: 50px auto 0px auto;
            a {
                display: block;
                height: 100%;
                @include transition(.3s);
                position: relative;
                &:hover {
                    .cta {
                        color: $noir;
                        /*border: 1px solid $orange;*/
                        background: $snow;
                        border: solid 1px $orange;
                    }
                    img {
                        @include filter(grayscale,
                        0%);
                    }
                    .cta_border_anim::after,
                    .cta_border_anim::before {
                        -webkit-transform: scale(1);
                    }
                }
                img {
                    
                    @include transition(.5s);
                    width: 100%;
                }
                span {
                    padding: 20px;
                    text-align: left;
                    @include transition(1s);
                    border: none;
                    &:hover {
                        border: none!important;
                    }
                    h4 {
                        text-align: center;
                        padding: 5px 0;
                    }
                    p {
                        @include font-size(16px);
                        color: $noir;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}


/*============= EVENEMENTS FIN =========================================== */

.reveal {
    padding: 0;
    .header_modal {}
    .close-button {
        color: $snow;
        background: $orange;
        font-size: 2.8em;
        padding: 5px 15px;
    }
    .content_modal_avatar {
        padding-top: 50px;
        padding-bottom: 50px;
        .description_modal {
            padding-left: 30px;
            h2 {
                margin-bottom: 30px;
            }
            h3 {
                font-weight: 700!important;
            }
            .details_formation {
                color: $orange;
                padding-bottom: 10px;
                display: block;
            }
        }
        .informations_modal {
            background: $orange;
            color: $snow;
            padding: 20px;
            h3 {
                color: $snow;
            }
            ul {
                margin-left: 0;
                li {}
            }
        }
    }
}


/*============= THERAPEUTE FIN =========================================== */


/*============= LOCAUX  =========================================== */

.locaux {
    text-align: center;
    h3 {
        margin-top: 60px;
    }
}


/*============= LOCAUX FIN =========================================== */


/*============= livre  =========================================== */

.livre {
    h2 {}
    video{
            width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
    }
    ul {
        li {
            height: 100%;
            display: block;
        }
    }
}


/*============= livre FIN =========================================== */


/*============= CONTENT FIN =========================================== */


/*============= FOOTER =============================================== */

footer {
    div {
        padding-bottom: 0!important;
        h2 {}
    }
    .donneecontact {
        background: rgba($snow, .8);
        position: absolute;
        top: 0;
        left: 11%;
        padding: 20px 30px 30px 30px;
        margin-top: 40px;
        height: 90%;
        h3 {
            padding: 0;
            margin: 0;
            margin-top: 40px;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-top: 40px;
            li {
                margin: 10px auto;
                a {
                    color: $noir;
                    &:active,
                    &:focus {
                        color: $noir;
                    }
                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }
    .map {
        position: relative;
    }
    #googleMap2 {
        position: relative !important;
        width: 100%;
        height: 400px !important;
        min-height: 80vh;
        border: 40px solid $snow;
    }
}


/*============= FOOTER FIN =========================================== */

.footer_13px {
    clear: both;
    a {}
    span {
        display: block;
        margin-top: 0;
        text-align: center;
        color: $noir;
        @include font-size(13px);
        padding: 13px;
        @include transition(0.5s);
        &:hover {
            color: $rose;
        }
    }
}

#404 {
    header {
        min-height: 100%;
    }
}

@include breakpoint(1090px down) {
    header {
        nav {
            ul {
                li {
                    a {
                        @include font-size(15px);
                    }
                }
            }
        }
    }
}

@include breakpoint(850px down) {
    header {
        nav {
            ul {
                li {
                    a {
                        @include font-size(13px);
                    }
                }
            }
        }
    }
}

@include breakpoint(small down) {

    .reveal{
        height: 100%!important;
        height: 100vh!important;
        overflow-y:scroll!important;
    }
    header{
        padding: 0;
        margin-bottom: 50px;
        min-height: 150%;
        .accueil{
            div{
                position: relative!important;
               -webkit-transform: translate(0)!important;
                -ms-transform: translate(0)!important;
                transform: translate(0)!important;
                top: auto!important;
                left: auto!important;
                width: 100%!important;
                height: 100vh!important;
                h1{
                    a{
                        height: 0;  
                    }
                }
            }
        }
    }
    h2{
        @include font-size(30px);
    }
    main{
        padding: 0!important;
    }
    .menu {
        height: 0px;
        display: inline-block;
        @include transition(height .3s);
        ul{
            li{
                a{
                    padding-top:15px;
                    padding-bottom: 10px;
                }
            }
        }
    }
    /*FONT-SIZE*/
    html,
    body {
        font-size: 95%;
    }
    .menu_visible {
        height: 310px !important;
        @include transition(height .3s);
        display: block!important;
        padding-top: 15px;
    }
    .navigation--button {
        display: block;
    }
    .sticky_menu {
        position: relative;
        display: none;

    }



    
}
